<template>
  <div class="section-container regular-text">
    <div class="position-relative pt-md-5 pb-3 pb-md-0 pt-5">  
      <div class="text-container">
        <p class="mb-3">
          En el mundo de las finanzas y los préstamos, cada día surgen nuevas alternativas que buscan satisfacer las necesidades de los clientes de manera rápida y confiable.
        </p>

        <p class="mb-5 mb-md-5">
          Una de las opciones que ha tomado fuerza es el <span class="bold-text">empeño de autos en México.</span> Un enfoque innovador y conveniente es el que ofrece Ibancar, que permite a los propietarios de vehículos obtener préstamos sin renunciar a la posesión de su coche. 
        </p>

        <h2 class="text-orange title bold-text mt-md-5 mt-3 mb-4">
          ¿Por qué elegir nuestro servicio de empeño?
        </h2>

        <div class="mb-5 pb-md-3">
          <p class="mb-3">
            Cuando se trata de empeñar un auto en México, es fundamental elegir un servicio confiable y eficiente. En este sentido, Ibancar destaca en varias áreas clave en comparación con otras empresas del sector.
          </p>
  
          <ul class="lista-icono">
            <li>
              Condiciones flexibles: Ofrecemos condiciones flexibles que se adaptan a las necesidades de sus clientes. No es necesario ir al notario, y el proceso es rápido y sencillo.
            </li>
            <li>
              Amortización en mensualidades: En Ibancar, puedes amortizar el préstamo en cómodas mensualidades, realizando aportaciones a capital desde el primer mes, lo que facilita la devolución del dinero prestado.
            </li>
            <li>
              Coche en depósito: A diferencia de otras empresas, no te pedimos que entregues tu coche como garantía, lo que te permite seguir usándolo mientras disfrutas de un préstamo.
            </li>
            <li>
              Importe máximo por tu coche: Ofrecemos hasta el 70% del valor de tu vehículo. Gracias a esto, puedes obtener préstamos significativamente más altos.
            </li>
            <li>
              Antigüedad del coche: En Ibancar, los vehículos de hasta 15 años de antigüedad son elegibles para el servicio, lo que brinda a los clientes más opciones.
            </li>
          </ul>
        </div>
        
        <h2 class="text-orange title bold-text mt-md-5 mt-3 mb-4">
          Pasos para empeñar tu auto en México
        </h2>

        <p class="mb-3">
          El proceso de <span class="bold-text">empeño de autos en México</span> con Ibancar es simple y rápido. Estos son los pasos básicos:
        </p>

        <p class="mb-3">
          <ol class="list-orange">
            <li>
              Solicitar el préstamo: visita nuestra página web y completa la solicitud en línea. En cuestión de minutos, recibirás una propuesta de préstamo personalizada sin compromiso.
            </li>
            <li>
              Envío de documentación: una vez que aceptes la propuesta, deberás enviar la documentación requerida, que incluye tu identificación oficial, una foto selfie con tu identificación, la factura y tarjeta de circulación de tu auto, así como fotos del tablero y del vehículo.
            </li>
            <li>
              Revisión de la documentación: nuestro equipo revisará tu documentación de manera inmediata.
            </li>
            <li>
              Aprobación y desembolso: si cumples con los requisitos, el dinero será transferido a tu cuenta en máximo 48 horas.
            </li>
          </ol>
        </p>
        <p class="mb-5">
          Estos pasos sencillos y ágiles hacen que el proceso de empeñar tu auto con Ibancar sea una opción atractiva para quienes necesitan liquidez de manera rápida y confiable.
        </p>

        <h2 class="text-orange title bold-text mt-md-5 mt-3 mb-4">
          Ventajas de empeñar tu vehículo con Ibancar
        </h2>

        <ul class="lista-icono">
          <li>
            Gestión eficaz y rápida: el proceso en Ibancar es rápido, lo que significa que puedes obtener el préstamo que necesitas en poco tiempo. Nuestros agentes ofrecen una atención amable y clara para garantizar una experiencia satisfactoria.
          </li>
          <li>
            Garantía de confiabilidad: Ibancar se enorgullece de haber ayudado a miles de clientes a obtener el dinero que necesitaban en los últimos 5 años. La satisfacción de los clientes es un testimonio de la calidad del servicio.
          </li>
          <li>
            No cambiamos el propietario del coche: esto significa que el vehículo sigue siendo tuyo y siempre lo será. Tampoco cobramos un importe por alquiler ni instalación de GPS o nada similar. Tu coche es solo un aval del préstamo.
          </li>
        </ul>

        <p class="mb-md-5 my-3">
          Si necesitas dinero rápidamente y deseas mantener la propiedad de tu coche, Ibancar es la solución perfecta para ti. Con más de 6 años de experiencia en el sector financiero y un equipo comprometido, estamos aquí para ayudarte a superar tus necesidades financieras de manera rápida y confiable.
        </p>
      </div>
    </div>
  </div>
  <SolicitaloBanner />
</template>
  
<script>
  import SolicitaloBanner from '../SolicitaloBanner.vue';

  export default {
    name: "EmpenoDeAutosEnMexicoS1",
    components: { SolicitaloBanner }
  };
</script>
  
<style scoped lang="scss">
  @import '@/assets/styles/variables.scss'; 
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss'; 
  
  .text-container{
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .regular-text{
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
  }

  .title {
    font-size: 25px;
    line-height: 30px;
  }
  .bold-text{
    font-family: "Montserrat-Bold";
  }
  .text-orange{
    color: $orange;
  }
  .text-blue{
    color: $blue;
  }

  .text-black{
    color: $black;
  }

  .text-underline{
    text-decoration: underline;
  }

  .background-orange-3{
    background-color: $orange-4;
  }

  .btn-solicitar{
    a{
      &:hover{
        text-decoration: none;
      }
    }
  }

  .list-orange{
    li{
      &::marker{
        color: $orange;
        font-family: "Montserrat-Bold";
      }
      margin-bottom: 1rem;
    }
  }

  .lista-icono{
    list-style: none;
    li{
      margin-bottom: 1rem;
      &:before{
        margin-left: -20px;
        content: '✓';
        color: $orange;
      }
    }
  }

  @include media-breakpoint-down(md){
    .text-container{
      width: 90%;
    }

    .regular-text{
      line-height: 1.5;
    }

    ul{
      padding-inline-start: 20px;
    }

    ol{
      padding-inline-start: 20px;
    }
  }

  @include media-breakpoint-between(md, lg){
    .regular-text{
      font-size: 16px;
    }

    .title{
      font-size: 28px;
      line-height: 35px;
    }
    .subtitle{
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(sm){
    .title{
      font-size: 20px;
      line-height: 25px;
    }
    .subtitle{
      font-size: 16px;
      line-height: 20px;
    }
    .regular-text{
      font-size: 16px!important;
    }
  }
</style>