<template>
    <div class="section-container regular-text">
        <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-5">
            <div class="text-container">
                <span class="bold-text">
                    <br>
                    ¿Buscas apoyo economico y al mismo tiempo quieres mantener tu carro?
                </span>
                <p class="mb-0">
                    En Ibancar, te brindamos <span class="bold-text">préstamo con garantía automotriz</span>, la
                    alternativa ideal si necesitas
                    efectivo rápido sin tener que dejar tu medio de transporte. Con más de 6 años brindando ayuda a
                    miles de clientes en España para enfrentar sus desafíos financieros, ahora traemos nuestro
                    compromiso y profesionalidad a México.
                </p>

                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">¿Cómo funcionan los préstamos con garanía automotriz?</h2>
                </span>
                <p class="mb-0">
                    Un <span class="bold-text">préstamo con garantía automotriz</span> te da la oportunidad de conseguir
                    dinero al instante utilizando
                    tu carro como aval, sin tener que dejarlo con nosotros. Solo es necesario que seas el dueño del
                    automóvil y tengas toda tu documentación en orden. Esta opción representa una vía rápida y confiable
                    para aquellos que requieren un apoyo económico urgente pero no quieren renunciar a su medio de
                    transporte. Ideal para enfrentar imprevistos o para cuando necesitas liquidez sin comprometer tu
                    movilidad diaria, nuestra opción de financiamiento se ajusta a tu vida sin complicaciones.
                </p>

                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">Ventajas de elegir Ibancar para tu préstamo con garanía automotriz</h2>
                </span>
                <p class="mb-0">
                <ul>
                    <li><span class="bold-text">Accesibilidad garantizada: </span> En Ibancar sabemos que las
                        circunstancias económicas pueden ser un desafío. Por eso, nuestros préstamos están diseñados
                        para ser accesibles a todos, brindándote la oportunidad de obtener financiamiento sin importar
                        tu situación económica actual.</li>
                    <li><span class="bold-text">Flexibilidad en los pagos: </span> Conscientes de que cada persona
                        enfrenta una realidad económica única, en Ibancar ofrecemos planes de pago que se ajustan a tu
                        capacidad económica, facilitando que administres tu préstamo de manera eficiente y sin estrés.
                    </li>
                    <li><span class="bold-text">Confianza y experiencia: </span> En Ibancar, contamos con años de
                        experiencia ayudando a las personas a alcanzar sus metas financieras con soluciones
                        transparentes y de alta calidad. Ahora en México, estamos listos para ofrecerte esa misma
                        confianza y seguridad, garantizando un servicio excepcional que se adapte a tus necesidades.
                        Nuestro recorrido nos respalda, brindándote la tranquilidad de estar en manos de expertos.</li>

                </ul>
                <br />

                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">¿Por qué nuestros clientes nos prefieren?</h2>
                </span>
                </p>
                <p class="mb-0">
                    Para nosotros, lo más importante es la satisfacción de nuestros clientes. Con un servicio que pone
                    atención especial en las necesidades particulares de cada quien, un proceso totalmente transparente
                    y un asesoramiento hecho a la medida, hemos alcanzado niveles sobresalientes de satisfacción entre
                    nuestros usuarios. Valoran enormemente la agilidad de nuestros servicios, lo sencillo que es acceder
                    a nuestros préstamos y el acompañamiento constante que les proporcionamos.

                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PrestamoConGarantiaPrendatariaS1",
    components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}

.background-orange-3 {
    background-color: $orange-4;
}

.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }
}
</style>