<template>
  <div class="section-container position-relative">
    <img v-if="windowWith >= 768"
         src="@/assets/img/prestamo-garantia-auto/persona-con-auto-y-telefono.svg"
         alt="Persona con teléfono"
         class="image position-absolute image-container" />
    <img v-else
         src="@/assets/img/prestamo-garantia-auto/auto-mobile.svg"
         alt="Persona con teléfono"
         class="image position-absolute image-container" />
    <div class="text-container p-3">
      <div class="row"
           v-if="title">
        <div class="col-10">
          <h1 class="mt-xl-4 title text-blue" v-html="title">
          </h1>
        </div>
      </div>
      <div class="row"
           v-if="subtitle && windowWith >= 768">
        <div class="col-10 col-md-7">
          <h2 class="subtitle text-blue mt-2">
            {{ subtitle }}
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-10 col-md-7 text-blue mt-2 regular-text">
          <p style="font-family: Montserrat-Bold;">
            ✓ Hasta <span class="text-orange">$120,000 en un día.</span>
          </p>
          <p style="font-family: Montserrat-Bold;">
            ✓ Solicitud <span class="text-orange"> en línea</span>
          </p>
          <p style="font-family: Montserrat-Bold;">
            ✓ <span class="text-orange">Aceptamos</span> personas en <br class="d-lg-none"> <span class="text-orange">Buró de crédito</span>
          </p>
          <div class="d-flex my-3 py-2 py-md-5 justify-content-start"
               v-if="windowWith > 1200">
            <div class="btn background-orange text-centertext-white regular-text btn-solicitar">
              <router-link to="#"
                           class="text-white"  @click="goTo('Home', '#banner-form')">Solicítalo en 2 minutos</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-3 text-center background-blue">
    <h1 class="subtitle text-white"
        style="margin:0">
      {{ windowWith > 768 ? 'Estamos registrados ante PROFECO' : 'La mejor alternativa al empeño de auto' }}
    </h1>
  </div>
</template>

<script>
export default {
  name: "BannerSection",
  props: {
    title: String,
    subtitle: String
  },
  data() {
    return {
      windowWith: window.innerWidth
    }
  },
  methods: {
    goTo(route, hash) {
            this.$router.push({ name: route, hash:hash  });
        },
    getWindowWidth() {
      this.windowWith = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.getWindowWidth);
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.section-container {
  min-height: 550px;
}

.text-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  min-height: inherit;
}

.title {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
}

.subtitle {
  font-family: 'Montserrat-Bold';
  font-size: 30px;
  font-weight: 600;
}

.regular-text {
  font-family: "Montserrat";
  font-size: 25px;
  font-weight: bold;
  p {
    margin-bottom: 3px;
  }
}

.text-blue {
  color: $blue;
}

.text-orange {
  color: $orange;
}

.background-blue {
  background-color: $blue;
}

.background-orange {
  background-color: $orange;
}

.btn-solicitar {
  text-decoration: none;
    border-radius: 30px;
    padding: 10px 65px;
    border-radius: 27.002px;
    background: var(--naranja-ffa-500, #FFA500);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-width: 250px;
    font-family: 'Montserrat-Bold';
  
    a {
      &:hover {
        text-decoration: none;
      }
    }
}

// Images styles
.image-container {
  z-index: -1;
  bottom: -95px;
  right: -2%;
  height: 640px;
}

@include media-breakpoint-down(lg) {
  .section-container {
    min-height: auto;
  }
}

@include media-breakpoint-between(md, lg) {
  // 768 a 1200

  .text-container {
    width: 85%;
    margin-left: 2rem;
  }

  .title {
    font-size: 28px;
  }

  .subtitle {
    font-size: 16px;
  }

  .regular-text {
    font-size: 18px;
  }

  .image-container {
    width: 50%;
  }

  @media (orientation: landscape) {
    .image-container {
      right: 0px
    }
  }
}

@include media-breakpoint-down(sm) {
  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 16px;
  }

  .regular-text {
    font-size: 14px !important;
  }

  .text-container {
    width: 85%;
    margin-left: 0.5rem;

    p {
      margin-bottom: 0;
    }
  }

  .image-container {
    width: 45%;
      max-width: 100%;
      bottom: -15%;
      height: auto;
      right: -4%;
  }

  .btn-orange {
    height: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .btn-solicitar {
    width: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

@include media-breakpoint-down(xs) {
  .btn-solicitar {
    min-width: 100px;
  }
}

@include media-breakpoint-between(md,lg){
  .title {
    font-size: $h2-font-size !important;
  }

  .subtitle {
    font-size: $h5-font-size;
  }

  .text-container {
    margin: 0rem 4rem;
  }
  .image-container {
    height: 315px;
    bottom:-56px !important;
  }
}

@include media-breakpoint-only(sm) {
  .image-container {
    width: 400px !important;
    bottom:-114px !important;
  }
}
</style>