<template>
  <div class="section-container regular-text">
    <div class="position-relative pt-md-5 pb-3 pb-md-0 pt-5">  
      <div class="text-container">
        <p class="mb-3">
          Cuando necesitas dinero urgente, pero no quieres deshacerte de tu vehículo, Ibancar es la solución perfecta.
        </p>

        <p class="mb-5">
          Esta innovadora empresa te ofrece la posibilidad de obtener un préstamo con la alternativa al <span class="bold-text">empeño de autos.</span> Pero, lo mejor de todo es que puedes utilizar tu coche como garantía, sin necesidad de cambiar el propietario del vehículo.
        </p>

        <h2 class="text-orange title bold-text mt-md-5 mt-3 mb-4">
          Ventajas de empeñar tu auto con Ibancar
        </h2>

        <div class="mb-5 pb-md-3">
          <p class="mb-3">
            Empeñar tu auto con nuestro servicio ofrece una serie de ventajas significativas que lo convierten en una opción atractiva para aquellos que necesitan financiamiento rápido y seguro:
          </p>
  
          <ul class="lista-icono">
            <li class="bold-text">
              No cambiamos el propietario del coche
              <br />
              <p class="regular-text">
                No cambiamos el propietario de tu vehículo. Esto implica que tu auto permanece bajo tu propiedad en todo momento, brindándote total tranquilidad. En contraste con otras alternativas de empeño, con Ibancar no estás obligado a entregar tu automóvil ni tampoco a incurrir en costosos alquileres por su uso.
              </p>
            </li>

            <li class="bold-text">
              Préstamo en poco tiempo
              <br />
              <p class="regular-text">
                Ibancar se enorgullece de tener un proceso rápido y eficiente. Gracias a su efectividad, puedes obtener el dinero solicitado en máximo 48 horas, lo que es esencial en situaciones de emergencia.
              </p>
            </li>

            <li class="bold-text">
              Condiciones flexibles
              <br />
              <p class="regular-text">
                Otra ventaja de Ibancar es que te permite amortizar el capital en mensualidades, lo que facilita la gestión de tu préstamo. Además, tiene la opción de pagos de gracia y días de cortesía para adaptar el pago a tus necesidades.
              </p>
            </li>

            <li class="bold-text">
              Proceso 100% online
              <br />
              <p class="regular-text">
                No tienes que dirigirte a ningún lugar para obtener tu préstamo. Todo el proceso de solicitud y gestión del financiamiento se realiza en línea, lo que te ahorra tiempo y esfuerzo.
              </p>
            </li>

            <li class="bold-text">
              Hasta el 70% del valor del vehículo
              <br />
              <p class="regular-text">
                Te ofrecemos la posibilidad de obtener un préstamo de hasta el 70% del valor de tu coche. Así tienes la opción de conseguir una fuente significativa de financiamiento para cubrir tus necesidades de capital.

              </p>
            </li>
          </ul>
        </div>
        
        <h2 class="text-orange title bold-text mt-md-5 mt-3 mb-4">
          Proceso de empeño en Ibancar: paso a paso
        </h2>

        <p class="mb-3">
          Si quieres aprovechar la modalidad de <span class="bold-text">empeño de autos</span> con Ibancar, solo debes seguir estos sencillos pasos: 
        </p>

        <p class="mb-3">
          <ol class="list-orange">
            <li>
              Visita nuestra web de Ibancar. Haz clic en el botón "Solicitar préstamo" para dar inicio a la solicitud. Indica el monto que necesitas.
            </li>
            <li>
              Ingresa la información del auto que será garantía del préstamo y tus datos de contacto para que Ibancar pueda comenzar a evaluar tu solicitud.
            </li>
            <li>
              Envío de propuesta de préstamo. Una vez que hayas proporcionado la información necesaria, recibirás una propuesta personalizada sin ningún compromiso por tu parte.
            </li>
            <li>
              Envío de documentación. Si decides seguir adelante, deberás enviar la documentación requerida. Esto incluye documentos personales como identificación oficial, último recibo de nómina, y documentación del vehículo, como la factura y la tarjeta de circulación, entre otros.
            </li>
            <li>
              Revisión de documentación. Ibancar revisará tu documentación de inmediato para agilizar el proceso.
            </li>
            <li>
              Ingreso del dinero. Una vez que tu documentación haya sido aprobada, Ibancar te transferirá el dinero en tu cuenta. 
            </li>
          </ol>
        </p>
        <p class="mb-5">
          Ibancar es una opción confiable y conveniente para obtener un préstamo utilizando tu coche como garantía. Su proceso rápido y seguro, combinado con condiciones flexibles para el pago. Miles de clientes satisfechos respaldan la eficacia de sus servicios. Así que no dudes en explorar el <span class="bold-text">empeño de autos</span> si necesitas resolver una emergencia financiera de manera rápida y confiable.
        </p>
      </div>
    </div>
  </div>
  <SolicitaloBanner />
</template>
  
<script>
  import SolicitaloBanner from '../SolicitaloBanner.vue';

  export default {
    name: "EmpenoDeAutosEnMexicoS1",
    components: { SolicitaloBanner }
  };
</script>
  
<style scoped lang="scss">
  @import '@/assets/styles/variables.scss'; 
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss'; 
  
  .text-container{
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .regular-text{
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
  }

  .title {
    font-size: 25px;
    line-height: 30px;
  }
  .bold-text{
    font-family: "Montserrat-Bold";
  }
  .text-orange{
    color: $orange;
  }
  .text-blue{
    color: $blue;
  }

  .text-black{
    color: $black;
  }

  .text-underline{
    text-decoration: underline;
  }

  .background-orange-3{
    background-color: $orange-4;
  }

  .btn-solicitar{
    a{
      &:hover{
        text-decoration: none;
      }
    }
  }

  .list-orange{
    li{
      &::marker{
        color: $orange;
        font-family: "Montserrat-Bold";
      }
      margin-bottom: 1rem;
    }
  }

  .lista-icono{
    list-style: none;
    li{
      margin-bottom: 1rem;
      &:before{
        margin-left: -20px;
        content: '✓';
        color: $orange;
      }
    }
  }

  @include media-breakpoint-down(md){
    .text-container{
      width: 90%;
    }

    .regular-text{
      line-height: 1.5;
    }

    ul{
      padding-inline-start: 20px;
    }

    ol{
      padding-inline-start: 20px;
    }
  }

  @include media-breakpoint-between(md, lg){
    .regular-text{
      font-size: 16px;
    }

    .title{
      font-size: 28px;
      line-height: 35px;
    }
    .subtitle{
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(sm){
    .title{
      font-size: 20px;
      line-height: 25px;
    }
    .subtitle{
      font-size: 16px;
      line-height: 20px;
    }
    .regular-text{
      font-size: 16px!important;
    }
  }
</style>