<template>
    <div class="section-container regular-text">
        <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-5">
            <div class="text-container">
                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">¿Cómo solicitar tú prestamo hoy?</h2>
                </span>
                <p class="mb-0">
                    Solicitar tu préstamo en Ibancar es un proceso directo. Solo necesitas tu identificación oficial, la
                    factura de tu auto y un poco de tu tiempo. Nuestro equipo de expertos te acompañará en cada paso,
                    garantizando una experiencia personalizada y transparente.
                </p>
                <br>
                <p>
                    En momentos de incertidumbre económica, contar con soluciones financieras fiables es esencial. En
                    Ibancar, comprendemos la necesidad de opciones accesibles y seguras para gestionar tus finanzas. Los
                    <span class="bold-text">préstamos con la factura de tu auto</span> ofrecen una solución práctica a
                    tus necesidades económicas inmediatas, permitiéndote mantener tu estilo de vida sin renunciar a tu
                    vehículo.
                </p>
                <br>
                <p>
                    Si te encuentras en una situación económica complicada y buscas una solución práctica, segura, y sin
                    tener que dejar tu auto, los préstamos con la factura de tu auto de Ibancar son tu mejor opción.
                    Contáctanos hoy y descubre cómo podemos ayudarte a retomar el control de tu situación financiera.
                </p>
            </div>
        </div>
    </div>



</template>

<script>

export default {
    name: "PrestamoFacturaAutoS2",
    components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}

.background-orange-3 {
    background-color: $orange-4;
}

.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }
}
</style>