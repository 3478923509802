<template>
    <div class="section-container regular-text">
        <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-5">
            <div class="text-container">
                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">Beneficios de elegir Ibancar para tu préstamo</h2>
                </span>
                <br>
                <p>
                    El proceso es sencillo y pensado en tu comodidad:
                    <br>
                </p>
                <ul>
                    <li><span class="bold-text">Seguridad y confianza: </span> Con Ibancar, tu préstamo está en manos de
                        expertos. Nuestra trayectoria de más de 6 años en el mercado español nos respalda, ofreciendo a
                        nuestros clientes en México la misma calidad y seguridad en cada transacción.</li>
                    <li><span class="bold-text">Proceso simple y rapido: </span> Sabemos que el tiempo es oro. Por eso,
                        nuestro proceso de solicitud es rápido y sin complicaciones, diseñado para que recibas tu
                        préstamo sin largas esperas.
                    </li>
                    <li><span class="bold-text">No dejes tu auto: </span> Entendemos la importancia de tu auto en tu día
                        a día. A diferencia de otros préstamos con garantía, con nosotros, tu auto se queda contigo.
                        Disfruta de la libertad de seguir moviéndote mientras gestionas tus finanzas.</li>
                </ul>

                <p class="mb-0">

                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Por qué Ibancar es tu mejor opción?</h2>
                    </span>
                </p>
                <p class="mb-0">
                    En Ibancar, nos dedicamos a solucionar tus problemas financieros con soluciones personalizadas.
                    Nuestro compromiso es ofrecer un servicio transparente y justo, sin letras pequeñas. Los testimonios
                    de nuestros usuarios, son prueba del compromiso y la calidad que ofrecemos.
                    <br>
                    Nuestro objetivo no es sólo proporcionar el financiamiento que necesitas, sino también asegurarnos
                    de que entiendas cada paso del proceso. Queremos que tomes decisiones informadas y te sientas seguro
                    y apoyado en cada momento.
                </p>
                <br>
                <p class="mb-0">

                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">Conntacta con nosotros, confía en Ibancar</h3>
                    </span>
                </p>
                <p class="mb-0">
                    Si estás atravesando una situación financiera difícil y necesitas apoyo, los <span
                          class="bold-text">préstamos con garantía de auto</span> sin dejarlo de Ibancar son la solución
                    que buscas. No permitas que las preocupaciones
                    económicas limiten tus posibilidades. Contacta con nosotros hoy mismo y descubre cómo podemos
                    ayudarte a retomar el control de tu vida financiera.
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PrestamoConGarantiaDeAutoSinDejarloS2",
    components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}

.background-orange-3 {
    background-color: $orange-4;
}

.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }
}
</style>