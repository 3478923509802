<template>
    <div class="banner-container mx-auto">
        <div class="row justify-content-center p-md-4 background-benefits">
            <div v-for="(ventaja, key) in ventajas"
                 class="col-12 col-md-6 col-xl-3 mt-md-2 mt-xl-0 py-2 py-md-0"
                 :key="key">
                <div class="mx-4 mx-md-2 card">
                    <div class="card-body d-flex align-items-center w-100 justify-content-center">
                        <div class="row d-flex w-100 align-items-center">
                            <div class="col-3 d-flex align-items-center">
                                <img :src="require(`@/assets/img/home/${ventaja.image}`)"
                                     :alt="ventaja.title"
                                     class="img-fluid" />
                            </div>
                            <div class="col-9 pl-2 pr-1 pl-lg-1">
                                <p class="card-text text-blue">
                                    {{ ventaja.title }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'PerksComponent',
    
  data() {
    return {
      ventajas: [
        {
          image: 'image12.svg',
          title: 'Ejecutivo personalizado',
        },
        {
          image: 'solicitud-en-linea.svg',
          title: 'Proceso simple sin salir de casa',
        },

        {
          image: 'prestamos-48-hrs.svg',
          title: 'Hasta $120,000 en 24H',
        },
        {
          image: 'proceso-simple.svg',
          title: 'Aceptamos personas en Buró.',
        }

      ]
    }
  }
}
</script>
<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.banner-container {
    width: 85%;
    max-height: 29rem;

}

.background-benefits {
  // background-color: #FFA5000A;
//   margin-bottom: 2rem !important;
}

.card-text {
  font-family: "Montserrat-Medium";
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 16px;
}

@include media-breakpoint-up(lg) {
  .background-benefits {
    background-color: white;
    margin: 1rem 0rem;
  }

  .card-text {
    font-size: 1rem;
  }

}

// Card
.card {
  border-radius: 0.625rem;
  border: 1px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.40));
  background: rgba(255, 255, 255, 0.90);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
}

.card-text {
  font-family: "Montserrat-Medium";
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 17px;
}

.text-blue {
  color: #004680;
}

@include media-breakpoint-down(sm) {
  .background-benefits {
    background-color: #FFA5000A;
    ;
  }
}

@include media-breakpoint-down(xs) {
  .background-benefits {
    padding: 1rem 0.5rem;
  }
}

@include media-breakpoint-down(md) {

    .banner-container {
        width: 100%;
    }
}

@include media-breakpoint-between(md, lg) {
  .banner-container {
    width: 80%;
  }
}
</style>