<template>
    <div class="section-container regular-text">
        <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-5">
            <div class="text-container">
                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">Nuestra experiencia nos avala</h2>
                </span>
                <p class="mb-0">
                    En Ibancar, lo más importante eres tú. Nos hemos ganado la confianza de más de 4,500 clientes,
                    facilitando más de 1.1 millones de euros al mes en préstamos, gracias a que siempre encontramos la
                    mejor manera de atender las necesidades únicas de cada quien con soluciones financieras a la medida
                    y un proceso totalmente claro y rápido. Nos especializamos en préstamos con tu auto como garantía,
                    ofreciéndote una opción segura y de calidad superior cuando buscas soluciones financieras sin
                    rollos. Aquí en México, seguimos comprometidos con brindar un servicio excelente, asegurándonos de
                    que todos nuestros clientes reciban la atención y el apoyo que se merecen, manteniendo el alto
                    estándar de calidad y confiabilidad que nos distingue.
                </p>
                <br>
                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">Conoce Ibancar: flexibilidad y confianza</h2>
                </span>
                <p class="mb-0">
                    En Ibancar, comprendemos lo abrumador que puede ser enfrentar situaciones financieras adversas. Es
                    por eso que ponemos a tu disposición nuestros préstamos con garantía prendaria, diseñados para
                    brindarte el soporte económico necesario, permitiéndote avanzar sin tener que desprenderte de tus
                    activos más valiosos, ya que una vez solicitado, podrás seguir utilizando tu coche. Buscamos
                    ofrecerte alternativas financieras que no solo sean flexibles y fáciles de acceder, sino también
                    completamente confiables. Nuestro equipo de profesionales está siempre listo para guiarte y
                    encontrar la solución que mejor se ajuste a tu situación.
                    <br>
                    No dejes que los retos económicos frenen tus planes o aspiraciones. Con Ibancar, obtén hoy mismo el
                    respaldo financiero esencial para ti y empieza a forjar un porvenir más seguro y prometedor. Te
                    invitamos a contactarnos para obtener más detalles sobre cómo podemos acompañarte en el camino hacia
                    tus objetivos financieros mediante un  <span class="bold-text">préstamos con garantía prendaria</span>. Estamos aquí para convertir
                    tus metas en realidad, apoyándote en cada paso del proceso.
                </p>
            </div>
        </div>
    </div>



</template>

<script>

export default {
    name: "PrestamoConGarantiaPrendariaS2",
    components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}

.background-orange-3 {
    background-color: $orange-4;
}

.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }
}
</style>