<template>
    <div class="section-container regular-text">
        <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-5">
            <div class="text-container">
                <p class="mb-0">
                    <span class="bold-text">¿Te encuentras en una situación económica complicada y necesitas una
                        solución confiable y rápida?
                        En Ibancar México, te ofrecemos</span> <span class="bold-text">préstamos con la factura de tu
                        auto</span>, una opción segura y accesible para obtener el apoyo financiero que necesitas,
                    manteniendo la posesión de tu vehículo. Con más de 6 años de experiencia satisfaciendo clientes en
                    España, ahora estamos aquí para apoyarte en México.
                </p>

                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">¿Cómo funcionan los préstamos con la factura de tu auto?</h2>
                </span>
                <p class="mb-0">
                    Obtener un préstamo con nosotros es sencillo y rápido. En Ibancar, ofrecemos <span
                          class="bold-text">préstamos con la factura de tu auto</span>, una solución rápida y segura
                    para obtener liquidez financiera sin renunciar a tu vehículo. El proceso incluye una evaluación
                    inicial de tu auto, la solicitud y entrega de documentación, y una verificación rápida. Tras la
                    aprobación, firmas un acuerdo y recibes el préstamo, con la posibilidad de seguir usando tu auto
                    mientras pagas. Al finalizar el pago, recuperas la factura sin condiciones adicionales. Esta opción
                    es ideal si estás atravesando un momento económico retador y necesitas liquidez sin sacrificar tu
                    movilidad.
                </p>

                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">¿Cuáles son las ventajas de elegir a Ibancar?</h2>
                </span>
                <p class="mb-0">
                    Con Ibancar, no solo obtienes un préstamo; ganas un aliado en tus finanzas personales. Nuestro
                    proceso es transparente, sin letras pequeñas. Aquí te mostramos por qué somos tu mejor opción:
                    <br />
                    <br />
                <ul>
                    <li><span class="bold-text">Seguridad y confianza: </span> Con más de 6 años de experiencia en
                        España, llegamos a México para ofrecerte el mismo nivel de profesionalismo y satisfacción.</li>
                    <li><span class="bold-text">Rápido y accesible: </span> Sabemos que tu tiempo es valioso. Por eso,
                        nuestro proceso de solicitud es rápido y sencillo, diseñado para que obtengas tu préstamo sin
                        complicaciones.</li>
                    <li><span class="bold-text">Tú coche sigue contigo: </span> A diferencia de otros tipos de
                        préstamos, con Ibancar puedes seguir utilizando tu coche mientras pagas el préstamo. Tu auto te
                        acompaña en cada momento de tu vida; nosotros nos aseguramos de que así sea.</li>

                </ul>
                <br />
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PrestamoGarantiaAutoS2",
    components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}

.background-orange-3 {
    background-color: $orange-4;
}

.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }
}
</style>