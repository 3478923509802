<template>
  <div class="body-container">
    <BannerSection title="Préstamos sobre auto en Ibancar: tu mejor alternativa" />
    <PrestamosSobreAutoEnIbancarS1 />
    <PreguntasFrecuentes />
  </div>
</template>
  
<script>
  import { useMeta } from 'vue-meta';
  import BannerSection from '@/components/Landings/BannerSection.vue';
  import PreguntasFrecuentes from '@/components/Landings/PreguntasFrecuentes.vue';
import PrestamosSobreAutoEnIbancarS1 from '@/components/Landings/PrestamosSobreAutoEnIbancar/PrestamosSobreAutoEnIbancarS1.vue';
  
  export default {
    name: 'PrestamosSobreAutoEnIbancar',
    components: {
    BannerSection,
    PreguntasFrecuentes,
    PrestamosSobreAutoEnIbancarS1
},
    setup() {
      useMeta(
        {
          title:'Préstamos sobre auto con Ibancar: soluciones rápidas en México',
          meta: [
            {
              name:'robots', 
              content:'index'
            },
            {
              name:'description', 
              content:'Con Ibancar, obtén préstamos sobre auto de manera sencilla y confiable. Aprovecha las mejores condiciones del mercado mexicano. ¡Conoce más!'
            },
            {
              name:'keywords', 
              content:'Préstamos sobre auto'
            }
          ],
          link: [
            { href: 'https://ibancar.com/prestamo-por-tu-coche', rel: 'alternate', hreflang: 'es-es' },
            { href: 'https://ibancar.mx/prestamos-sobre-auto-en-ibancar', rel: 'alternate', hreflang: 'es-MX' }
          ]
        }
      )
    }
  }
</script>
  
<style scoped lang="scss">

  @import 'bootstrap/scss/_functions.scss'; 
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss';

  .body-container{
    margin-top: 115px;
    min-height: calc(100vh - 115px);
  }

  @include media-breakpoint-down(sm){
    .body-container{
      margin-top: 120px;
    }
  }

  @include media-breakpoint-down(xs){
    .body-container{
      margin-top: 70px;
    }
  }
</style>