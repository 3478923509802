<template>
  <div class="section-container regular-text">
    <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-2">
      <div class="text-container">
        <div class="text-dudas">
        </div>
        <p class="mb-0">
          <span class="bold-text">¿Estás buscando un préstamo rápido y no sabes a quien acudir? ¡No te preocupes!
            Ibancar ofrece el crédito donde utilizas tu vehículo como garantía.</span> <br />
          Con Ibancar, puedes conseguir <span class="bold-text">el dinero que necesitas en 48 horas</span> y sin tener
          que sacrificar la propiedad del vehículo. A continuación, veamos cómo funciona el crédito de Ibancar y por qué
          es una opción tan atractiva.
        </p>
        <br>
        <span class="bold-text">
          <h2 style="font-size: 20px;">¿Cómo funciona el préstamo de Ibancar?</h2>
        </span>
        <p class="mb-0">
          Al solicitar un préstamo en Ibancar, <span class="bold-text">tu vehículo se utilizará como garantía.
          </span>Esto significa que no necesitas involucrar a otras personas ni esperar la aprobación de tu banco. Y lo
          mejor de todo es que Ibancar no te quita el vehículo, no realiza cambio de propietario y no impone condiciones
          sobre cómo usarlo mientras pagas el crédito.
        </p>

        <br>
        <span class="bold-text">
          <h3 style="font-size: 20px;">¿Cuáles son las ventajas del préstamo en Ibancar?</h3>
        </span>
        <p class="mb-0">
          Una de las principales ventajas del crédito de auto que con Ibancar no se retira el vehiculo, no se hace
          cambio de propietario y no se imponen condiciones mientras pagas el crédito.
          <br />
        </p>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: "PrestamoGarantiaAutoS4",
  components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.text-dudas {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}
.text-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.regular-text {
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 30px;
}

.title {
  font-size: 25px;
  line-height: 30px;
}

.bold-text {
  font-family: "Montserrat-Bold";
}

.text-orange {
  color: $orange;
}

.text-blue {
  color: $blue;
}

.text-black {
  color: $black;
}

.text-underline {
  text-decoration: underline;
}

.background-orange-3 {
  background-color: $orange-4;
}

.btn-solicitar {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

// Image
.image {
  bottom: 0;
  right: 0;
  width: 30%;
  z-index: -1;
}

@include media-breakpoint-down(md) {
  .text-container {
    width: 90%;
  }

  .regular-text {
    line-height: 1.5;
  }

  ul {
    padding-inline-start: 20px;
  }

  ol {
    padding-inline-start: 20px;
  }
}

@include media-breakpoint-between(md, lg) {
  .regular-text {
    font-size: 16px;
  }

  .title {
    font-size: 28px;
    line-height: 35px;
  }

  .subtitle {
    font-size: 16px;
  }
}

@include media-breakpoint-down(sm) {
  .title {
    font-size: 20px;
    line-height: 25px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  .regular-text {
    font-size: 16px !important;
  }
}
</style>