<template>
    <div class="body-container">
        <BannerSection title="Préstamos con garantia automotriz"
        subtitle="Tu solución financiera en momentos dificiles"
                        />
            <PerksComponent></PerksComponent>
        <PrestamoConGarantiaAutomotrizS1 />
        <div class="ibancar-election">
            <p>¿Por qué elegir Ibancar?</p>
        </div>
        <BeneficiosIbancarCarousel />
        <!-- <SolicitaloBanner/> -->
        <PrestamoConGarantiaAutomotrizS2 />
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PrestamoConGarantiaAutomotrizS1 from '@/components/Landings/PrestamoConGarantiaAutomotriz/PrestamoConGarantiaAutomotrizS1.vue';
import PrestamoConGarantiaAutomotrizS2 from '@/components/Landings/PrestamoConGarantiaAutomotriz/PrestamoConGarantiaAutomotrizS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamoConGarantiaAutomotriz',
    components: {
        BannerSection,
        PerksComponent,
        BeneficiosIbancarCarousel,
        PrestamoConGarantiaAutomotrizS1,
        PrestamoConGarantiaAutomotrizS2,
        SolicitaloBannerS2
    },
    setup() {
        useMeta(
            {
                title: 'Préstamo con garantía automotriz',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Transforma lo que tienes en lo que necesitas: obtén un préstamo con garantía prendaria. Condiciones flexibles, tasas justas y atención a tu medida. ¡Haz que tus bienes trabajen para ti hoy mismo!'
                    },
                    {
                        name: 'keywords',
                        content: 'préstamo con garantía prendaria'
                    }
                ]
            }
        )
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.body-container {
    margin-top: 115px;
    min-height: calc(100vh - 115px);
}

.perks-container {

    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #FFA5000A;

    div {
        gap: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ibancar-election {
    margin: 2rem 2rem;

    p {
        color: #004680;
        text-align: center;

        /* Mobile 360 px/H2 */
        font-family: 'Montserrat-Bold';
        font-size: 1.2rem;
        font-style: normal;
        line-height: normal;
    }
}

@include media-breakpoint-up(lg) {
    .perks-container {
        background-color: white;
    }
}

@include media-breakpoint-down(sm) {
    .body-container {
        margin-top: 84px;
    }
}
</style>