<template>
    <div class="section-container regular-text">
        <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-5">
            <div class="text-container">
                <p class="mb-0">
                    En Ibancar México, ofrecemos una solución financiera inmediata para aquellos momentos en los que
                    necesitas un apoyo económico sin complicaciones. Los <span class="bold-text">préstamos con factura
                        de coche</span> te permiten
                    acceder a un préstamo utilizando tu vehículo como garantía, sin la necesidad de dejar de usarlo.
                    Este método se ha convertido en una opción popular para quienes buscan liquidez rápida, manteniendo
                    la posibilidad de seguir disfrutando de su medio de transporte.

                </p>

                <span class="bold-text">
                    <br>
                    Ventajas de elegir Ibancar
                </span>
                <p class="mb-0">
                    Con más de 6 años de experiencia en el mercado de España, Ibancar llega a México para ofrecer
                    <span class="bold-text">préstamos con factura
                        de coche</span> con la misma calidad y confianza que nos caracteriza. Elegirnos
                    significa acceder a un servicio que valora la transparencia, la rapidez y la seguridad en cada
                    transacción. Nuestros clientes aprecian la facilidad de nuestro proceso, nuestras tasas competitivas
                    y el respaldo de un equipo que se preocupa genuinamente por tu bienestar financiero.

                </p>

                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">Características del servicio de los préstamos por factura de carro: fácil y rapido.</h2>
                </span>
                <p class="mb-0">
                    <br>
                    Sabemos que cuando necesitas ayuda financiera, el tiempo es esencial. Por ello, hemos optimizado
                    nuestro proceso de solicitud para que sea lo más sencillo y rápido posible. Solo necesitas tu
                    identificación oficial, la factura de tu coche y unos minutos para completar tu solicitud en línea.
                    Nuestro equipo evaluará tu caso de inmediato y te ofrecerá una solución personalizada, asegurando
                    que recibas el apoyo financiero que necesitas sin demoras.
                    <br />
                    <br />
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PrestamoPorFacturaAutoS1",
    components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}

.background-orange-3 {
    background-color: $orange-4;
}

.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }
}
</style>