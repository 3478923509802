<template>
  <div class="section-container regular-text">
    <div class="position-relative pt-md-5 pb-3 pb-md-0 pt-5">  
      <div class="text-container">
        <p class="mb-3">
          Es más común de lo que se piensa atravesar situaciones financieras complicadas. Y es aquí dónde obtener préstamos de manera rápida y sencilla es la mejor salida.
        </p>
        <p class="mb-3">
          Ya sea para hacer frente a gastos personales inesperados, realizar inversiones o cumplir con deudas urgentes, la necesidad de conseguir dinero se ha vuelto una constante en nuestras vidas. 
        </p>
        <p class="mb-3 pb-3">
          En este contexto, Ibancar se presenta como una solución innovadora y eficaz para quienes tienen un auto, pues este les sirve como aval para su financiamiento.
        </p>

        <h2 class="text-orange title bold-text mt-md-5 mt-3 mb-4">
          ¿Por qué elegir Ibancar para préstamos sobre auto?
        </h2>

        <div class="mb-md-5 pb-3">
          <p class="mb-3">
            Ibancar ha revolucionado la forma en que se obtienen préstamos utilizando tu propio vehículo como garantía. Es tu mejor alternativa porque:
          </p>
  
          <ul class="lista-icono">
            <li class="bold-text">
              No pierdes la titularidad del coche
              <br />
              <p class="regular-text">
                A pesar de que tu auto funciona como garantía, Ibancar no cambia el propietario. Esto significa que puedes obtener el dinero que necesitas y el coche sigue siendo tuyo y siempre lo será. Olvídate de complicados trámites notariales y de perder la posesión de tu vehículo.
              </p>
            </li>

            <li class="bold-text">
              Crédito personalizado al instante
              <br />
              <p class="regular-text">
                La plataforma te permite obtener una propuesta de préstamo personalizada en tan solo minutos y sin ningún compromiso. Luego puedes hacer tu solicitud y si es aprobada, lo recibirás en tu cuenta en máximo 48 horas. Esto significa que no tendrás que pasar por complicados procesos de gestión para obtener el dinero. 
              </p>
            </li>

            <li class="bold-text">
              Puedes obtener hasta el 70% del valor de tu vehículo
              <br />
              <p class="regular-text">
                El valor de tu vehículo es un factor crítico en la cantidad que puedes obtener. La capacidad de Ibancar para ofrecerte hasta el 70% del valor de tu coche significa que podrás acceder a un monto considerable, lo que te permitirá cumplir con tus necesidades financieras de manera más efectiva.
              </p>
            </li>

            <li class="bold-text">
              Amortización del capital en mensualidades
              <br />
              <p class="regular-text">
                Este aspecto juega un papel crucial en la gestión de tu préstamo y puede marcar la diferencia en tu experiencia financiera. En lugar de enfrentar pagos de sólo intereses cada mes y un pago final al terminar el plazo, puedes distribuirlo en mensualidades manejables a lo largo del período acordado. Esto facilita la planificación de tus pagos mensuales y te ayuda a mantener un presupuesto más equilibrado.
              </p>
            </li>
          </ul>
        </div>
        
        <h2 class="text-orange title bold-text mt-md-5 mt-3 mb-4">
          Pasos para obtener tu préstamo sobre auto con Ibancar
        </h2>

        <p class="mb-3">
          Si estás considerando obtener <span class="bold-text">préstamos sobre auto</span> con Ibancar, te proporcionamos una guía paso a paso para que puedas realizar el proceso de manera eficiente y sin complicaciones:
        </p>

        <p class="mb-md-4 mb-3">
          <ol class="list-orange">
            <li>
              Visita la Página Web de Ibancar. Allí podrás encontrar toda la información necesaria sobre los préstamos sobre auto que ofrecen.
            </li>
            <li>
              Realiza tu solicitud. La opción encontrarás en un botón o un enlace claramente identificado para iniciar el proceso de solicitud.
            </li>
            <li>
              Proporciona la información necesaria. Se te solicitará ingresar la información de tu vehículo y otros detalles relevantes sobre el mismo. También deberás proporcionar tu información personal.
            </li>
            <li>
              Recibe una propuesta. Después de enviar tu solicitud, Ibancar revisará la información y calculará una propuesta personalizada de las condiciones, el monto, la tasa de interés y los plazos de pago.
            </li>
            <li>
              Envía la documentación requerida. Esto puede incluir tu identificación oficial, factura y tarjeta de circulación de tu auto, así como fotos del mismo. 
            </li>
            <li>
              Revisión y aprobación. Ibancar comprobará la documentación enviada y realizará una evaluación de tu vehículo. Si todo está en orden y cumples con los requisitos, obtendrás la aprobación de tu solicitud.
            </li>
            <li>
              Recibe el dinero en tu cuenta. Cuando se apruebe el préstamo, te transferirán el dinero a tu cuenta bancaria en máximo 48 horas.
            </li>
          </ol>
        </p>
        <p class="my-md-5 my-3">
          Si estás buscando una solución financiera flexible y confiable de <span class="bold-text">préstamos sobre auto</span>, Ibancar es tu mejor alternativa en México. 
        </p>
      </div>
    </div>
  </div>
  <SolicitaloBanner />
</template>
  
<script>
  import SolicitaloBanner from '../SolicitaloBanner.vue';

  export default {
    name: "PrestamosSobreAutoEnIbancar",
    components: { SolicitaloBanner }
  };
</script>
  
<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss'; 
  .text-container{
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .regular-text{
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
  }

  .title {
    font-size: 25px;
    line-height: 30px;
  }
  .bold-text{
    font-family: "Montserrat-Bold";
  }
  .text-orange{
    color: $orange;
  }
  .text-blue{
    color: $blue;
  }

  .text-black{
    color: $black;
  }

  .text-underline{
    text-decoration: underline;
  }

  .background-orange-3{
    background-color: $orange-4;
  }

  .btn-solicitar{
    a{
      &:hover{
        text-decoration: none;
      }
    }
  }

  .list-orange{
    li{
      &::marker{
        color: $orange;
        font-family: "Montserrat-Bold";
      }
      margin-bottom: 1rem;
    }
  }

  .lista-icono{
    list-style: none;
    li{
      margin-bottom: 1rem;
      &:before{
        margin-left: -20px;
        content: '✓';
        color: $orange;
      }
    }
  }

  @include media-breakpoint-down(md){
    .text-container{
      width: 90%;
    }

    .regular-text{
      line-height: 1.5;
    }

    ul{
      padding-inline-start: 20px;
    }

    ol{
      padding-inline-start: 20px;
    }
  }

  @include media-breakpoint-between(md, lg){
    .regular-text{
      font-size: 16px;
    }

    .title{
      font-size: 28px;
      line-height: 35px;
    }
    .subtitle{
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(sm){
    .title{
      font-size: 20px;
      line-height: 25px;
    }
    .subtitle{
      font-size: 16px;
      line-height: 20px;
    }
    .regular-text{
      font-size: 16px!important;
    }
  }
</style>