<template>
    <div class="section-container regular-text">
        <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-5">
            <div class="text-container">
                <p class="mb-0">
                    En estos momentos de incertidumbre económica, es fundamental tener a la mano un socio financiero de
                    confianza. En Ibancar, con más de 6 años de éxito en España ofreciendo soluciones financieras
                    seguras y personalizadas, entendemos a la perfección lo que necesitas. Nuestros <span
                          class="bold-text">préstamos con garantía prendaria</span> están especialmente diseñados para
                    ofrecerte esa solidez financiera que estás
                    buscando, dándote la posibilidad de seguir adelante con tu vida diaria sin inquietudes.
                </p>

                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">¿En que consiste un préstamo con garantía prendaria?</h2>
                </span>
                <p class="mb-0">
                    Un <span class="bold-text">préstamo con garantía prendaria</span> te brinda la posibilidad de acceder
                    a financiamiento de forma inmediata, ofreciendo algún bien como respaldo, sin la necesidad de
                    venderlo. Esta alternativa resulta perfecta cuando necesitas liquidez financiera sin poner en riesgo
                    tus bienes más preciados.
                </p>

                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px">Ventajas de los prestamos con garantía prendaria en Ibancar</h2>
                </span>
                <p class="mb-0">
                <ul>
                    <li><span class="bold-text">Accesibilidad: </span> No importa cuál sea tu situación financiera, en
                        Ibancar te ofrecemos préstamos diseñados para ser alcanzables por todos.</li>
                    <li><span class="bold-text">Flexibilidad: </span> Modificamos los términos del préstamo para que se
                        ajusten precisamente a lo que necesitas, con esquemas de pago que se adaptan a tu situación
                        económica particular.</li>
                    <li><span class="bold-text">Confianza: </span> Respaldados por más de 6 años brindando un servicio
                        de primera en España y logrando altos niveles de satisfacción entre nuestros clientes, en
                        Ibancar te aseguramos una experiencia transparente y de confianza.</li>

                </ul>
                <br />

                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">¿Cómo obtener tu préstamo fácilmente?</h2>
                </span>
                </p>
                <p class="mb-0">
                <ul>
                    <li><span class="bold-text">Evaluacuon inmediata: </span> Solo llena nuestra solicitud en línea para
                        recibir una evaluación preliminar sin compromiso.</li>
                    <li><span class="bold-text">Documentacion básica: </span> Necesitarás tu identificación oficial y la
                        factura del vehículo que usarás como garantía.</li>
                    <li><span class="bold-text">Desembolso rápido: </span> Una vez aprobado, el préstamo se deposita
                        directamente en tu cuenta bancaria, dándote acceso rápido y seguro a los fondos.</li>

                </ul>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PrestamoConGarantiaPrendatariaS1",
    components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}

.background-orange-3 {
    background-color: $orange-4;
}

.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }
}
</style>