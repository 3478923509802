<template>
    <div class="body-container">
        <BannerSection title="Obten préstamos con la factura <br class='d-none d-lg-block'/> de tu auto en Ibancar"
                       subtitle="Recupera tu tranquilidad financiera" />

        <PerksComponent></PerksComponent>
        <PrestamoFacturaAutoS1 />
        <div class="ibancar-election">
            <p>¿Por qué elegir Ibancar?</p>
        </div>
        <BeneficiosIbancarCarousel />
        <!-- <SolicitaloBanner/> -->
        <PrestamoFacturaAutoS2 />
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PrestamoFacturaAutoS1 from '../components/Landings/PrestamoFacturaAuto/PrestamoFacturaAutoS1.vue';
import PrestamoFacturaAutoS2 from '../components/Landings/PrestamoFacturaAuto/PrestamoFacturaAutoS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamoFacturaAuto',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        PerksComponent,
        PrestamoFacturaAutoS1,
        PrestamoFacturaAutoS2,
        SolicitaloBannerS2
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos con la factura de tu auto',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Consigue efectivo al instante con préstamos respaldados por la factura de tu auto. Trámite fácil y rápido, sin perder el uso de tu carro. ¡Checa los detalles y sigue rodando sin parar!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos con la factura de tu auto'
                    }
                ]
            }
        )
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.body-container {
    margin-top: 115px;
    min-height: calc(100vh - 115px);
}

.perks-container {

    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #FFA5000A;

    div {
        gap: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ibancar-election {
    margin: 2rem 2rem;

    p {
        color: #004680;
        text-align: center;

        /* Mobile 360 px/H2 */
        font-family: 'Montserrat-Bold';
        font-size: 1.5rem;
        font-style: normal;
        line-height: normal;
    }
}

@include media-breakpoint-up(lg) {
    .perks-container {
        background-color: white;
    }
}

@include media-breakpoint-down(sm) {
    .body-container {
        margin-top: 84px;
    }
}
</style>