<template>
    <div class="section-container regular-text">
        <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-5">
            <div class="text-container">
                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">Da el primer paso hacia tu estabilidad financiera</h2>
                </span>
                <br/>
                <p class="mb-0">
                    Inicia el camino hacia tu estabilidad financiera sin que las dificultades económicas reduzcan tus
                    alternativas. Con los <span class="bold-text">préstamos con garantía automotriz en Ibancar </span>
                    de Ibancar, consigue el apoyo financiero
                    necesario sin perder el control de tu vehículo. Te invitamos a visitar nuestro sitio web para
                    iniciar tu solicitud o para contactarnos directamente y obtener más detalles. En Ibancar, estamos
                    comprometidos a apoyarte en superar tus retos financieros, ofreciéndote nuestra asistencia en cada
                    paso hacia la consolidación de tu bienestar económico. Permítenos ser tu aliado en este proceso,
                    brindándote soluciones profesionales y adaptadas a tus necesidades para fortalecer tu estabilidad
                    financiera.

                </p>
                <br>
            </div>
        </div>
    </div>



</template>

<script>

export default {
    name: "PrestamoConGarantiaAutomotrizS2",
    components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}

.background-orange-3 {
    background-color: $orange-4;
}

.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }
}
</style>