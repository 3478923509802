<template>
  <div class="body-container">
    <BannerSection title="Empeño de autos en Ibancar: rápido, seguro y confiable" />
    <EmpenoDeAutoEnIbancarS1 />
    <PreguntasFrecuentes />
  </div>
</template>
  
<script>
  import { useMeta } from 'vue-meta';
  import BannerSection from '@/components/Landings/BannerSection.vue';
  import PreguntasFrecuentes from '@/components/Landings/PreguntasFrecuentes.vue';
import EmpenoDeAutoEnIbancarS1 from '../components/Landings/EmpenoDeAutoEnIbancar/EmpenoDeAutoEnIbancarS1.vue';
  
  export default {
    name: 'EmpenoDeAutoEnIbancar',
    components: {
    BannerSection,
    PreguntasFrecuentes,
    EmpenoDeAutoEnIbancarS1
},
    setup() {
      useMeta(
        {
          title:'Empeño de autos con Ibancar: la mejor opción en México',
          meta: [
            {
              name:'robots', 
              content:'index'
            },
            {
              name:'description', 
              content:'Descubre con Ibancar cómo empeñar tu auto de forma rápida y segura. Obtén el mejor trato y las condiciones más favorables en México. ¡Infórmate ahora!'
            },
            {
              name:'keywords', 
              content:'Empeño de autos en México'
            }
          ],
          link: [
            { href: 'https://ibancar.com/empenar-coche', rel: 'alternate', hreflang: 'es-es' },
            { href: 'https://ibancar.mx/empeno-de-autos-en-ibancar', rel: 'alternate', hreflang: 'es-MX' }
          ]
        }
      )
    }
  }
</script>
  
<style scoped lang="scss">
  .body-container{
    margin-top: 115px;
    min-height: calc(100vh - 115px);
  }
</style>