<template>
  <div class="body-container">
    <BannerSection title="Empeña tu auto y continúa usándolo" />
    <EmpenaTuAutoContinuaUsandoloS1 />
    <PreguntasFrecuentes />
  </div>
</template>
  
<script>
  import { useMeta } from 'vue-meta';
  import BannerSection from '@/components/Landings/BannerSection.vue';
  import EmpenaTuAutoContinuaUsandoloS1 from '@/components/Landings/EmpenaTuAutoContinuaUsandolo/EmpenaTuAutoContinuaUsandoloS1.vue';
  import PreguntasFrecuentes from '@/components/Landings/PreguntasFrecuentes.vue';
  
  export default {
    name: 'EmpenaTuAutoContinuaUsandolo',
    components: {
      BannerSection,
      PreguntasFrecuentes,
      EmpenaTuAutoContinuaUsandoloS1
    },
    setup() {
      useMeta(
        {
          title:'Empeña tu auto sin dejarlo con Ibancar',
          meta: [
            {
              name:'robots', 
              content:'index'
            },
            {
              name:'description', 
              content:'Descubre cómo empeñar tu vehículo y seguir disfrutándolo. Obtén liquidez inmediata sin renunciar a tu auto. ¡Conoce las opciones y beneficios de Ibancar!'
            },
            {
              name:'keywords', 
              content:'Empeña tu auto sin dejarlo, empeñar auto sin dejarlo, empeño de autos sin dejarlo'
            }
          ],
          link: [
            { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
            { href: 'https://ibancar.mx/prestamo-garantia-auto', rel: 'alternate', hreflang: 'es-MX' }
          ]
        }
      )
    }
  }
</script>
  
<style scoped lang="scss">
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss'; 
  .body-container{
    margin-top: 115px;
    min-height: calc(100vh - 115px);
  }

  @include media-breakpoint-down(md){
    .body-container{
      margin-top: 100px;
    }
  }

  @include media-breakpoint-down(sm){
    .body-container{
      margin-top: 120px;
    }
  }
</style>