<template>
    <div class="section-container regular-text">
        <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-5">
            <div class="text-container">
                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">¿Por qué escoger nuestros servicios?</h2>
                </span>
                <p class="mb-0">
                    En Ibancar, sabemos perfectamente que navegar por aguas turbulentas en tiempos económicos difíciles
                    representa un gran desafío. Pero aquí creemos con firmeza que cada persona merece tener acceso a
                    soluciones financieras que sean justas, claras y, sobre todo, transparentes, especialmente en esos
                    momentos cuando la necesidad apremia. Nuestros <span class="bold-text">préstamos con factura de tu
                        coche</span> están especialmente
                    diseñados para brindarte ese oasis de tranquilidad financiera que tanto buscas, permitiéndote
                    manejar tus necesidades económicas actuales sin tener que renunciar a tu movilidad ni a tu ritmo de
                    vida.
                </p>
                <br>
                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">Experiencia sólida y confiable</h2>
                </span>
                <p class="mb-0">
                    La solidez de nuestra experiencia y la satisfacción que han expresado nuestros clientes en España
                    hablan por sí solas del compromiso que tenemos con la excelencia en el servicio y el soporte al
                    cliente. Al poner en marcha nuestra operación en México, llevamos esta misma tradición de compromiso
                    y calidad, ofreciéndote un servicio en el que puedes depositar tu confianza plena. No importa cuál
                    sea tu situación particular, en Ibancar estamos listos y equipados para ayudarte a superar esos
                    desafíos financieros mediante un préstamo por factura de carro que se adapte perfectamente a tus
                    necesidades individuales.
                </p>
                <br>
                <p>
                    Te invitamos a descubrir por ti mismo cómo podemos ser el apoyo financiero que necesitas.
                    Contáctanos hoy mismo y experimenta la diferencia de trabajar mano a mano con un verdadero líder en
                    el ámbito de las soluciones financieras. Juntos, podemos trazar el camino hacia una estabilidad
                    económica más segura y prometedora.
                </p>
            </div>
        </div>
    </div>



</template>

<script>

export default {
    name: "PrestamoPorFacturaAutoS2",
    components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}

.background-orange-3 {
    background-color: $orange-4;
}

.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }
}
</style>