<template>
  <div class="body-container">
    <BannerSection title="Empeño de autos en México: rápido y confiable" />
    <EmpenoDeAutosEnMexicoS1 />
    <PreguntasFrecuentes />
  </div>
</template>
  
<script>
  import { useMeta } from 'vue-meta';
  import BannerSection from '@/components/Landings/BannerSection.vue';
  import PreguntasFrecuentes from '@/components/Landings/PreguntasFrecuentes.vue';
  import EmpenoDeAutosEnMexicoS1 from '@/components/Landings/EmpenoDeAutosEnMexico/EmpenoDeAutosEnMexicoS1.vue';
  
  export default {
    name: 'EmpenoDeAutosEnMexico',
    components: {
      BannerSection,
      PreguntasFrecuentes,
      EmpenoDeAutosEnMexicoS1
    },
    setup() {
      useMeta(
        {
          title:'Empeño de autos en México',
          meta: [
            {
              name:'robots', 
              content:'index'
            },
            {
              name:'description', 
              content:'El mejor servicio de empeño de autos en México con Ibancar. Obtén préstamos rápidos, seguros y con las mejores tasas. ¡Transforma tu vehículo en efectivo ahora!'
            },
            {
              name:'keywords', 
              content:'Empeño de autos en México, ventajas de empeñar tu auto'
            }
          ],
          link: [
            { href: 'https://ibancar.com/empenar-coche-mas-diez-anos', rel: 'alternate', hreflang: 'es-es' },
            { href: 'https://ibancar.mx/empeno-de-auto-en-mexico', rel: 'alternate', hreflang: 'es-MX' }
          ]
        }
      )
    }
  }
</script>
  
<style scoped lang="scss">

  @import 'bootstrap/scss/_functions.scss'; 
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss';

  .body-container{
    margin-top: 115px;
    min-height: calc(100vh - 115px);
  }

  @include media-breakpoint-down(sm){
    .body-container{
      margin-top: 135px;
    }
  }

  @include media-breakpoint-down(xs){
    .body-container{
      margin-top: 70px;
    }
  }
</style>