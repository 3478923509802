<template>
    <div class="section-container regular-text">
        <div class="position-relative  pt-md-5 pb-3 pb-md-0 pt-5">
            <div class="text-container">
                <p class="mb-0">
                    En momentos donde la estabilidad económica se ve amenazada, es común sentir que las opciones para
                    obtener ayuda financiera son limitadas. Sin embargo, en Ibancar, te ofrecemos una alternativa
                    segura, confiable y sobre todo accesible: <span class="bold-text">préstamos con garantía de auto sin
                        dejarlo</span>. Con más de 6
                    años de experiencia en España, hemos logrado expandir nuestro servicio a México, brindando
                    soluciones financieras a quienes más lo necesitan, asegurando satisfacción y tranquilidad a nuestros
                    usuarios.
                </p>

                <span class="bold-text">
                    <br>
                    <h2 style="font-size: 20px;">¿Qué es un préstamo con garantía de auto y cómo funciona?</h2>
                </span>
                <p class="mb-0">
                    Un <span class="bold-text">préstamos con garantía de auto sin
                        dejarlo</span> es un tipo de financiamiento único que te permite usar
                    tu coche como garantía para obtener un préstamo, sin la necesidad de dejar de usarlo. Esto significa
                    que mientras estás pagando el crédito, tu vehículo sigue siendo parte de tu vida cotidiana, sin
                    causar inconvenientes o interrumpir tus actividades diarias.
                    <br>
                    Este modelo de préstamo es ideal para quienes necesitan liquidez económica de manera inmediata, pero
                    también valoran la importancia de mantener su medio de transporte. La flexibilidad es uno de los
                    mayores beneficios de este tipo de préstamo, ya que te ofrece soluciones financieras sin sacrificar
                    tu movilidad.

                </p>

                <span class="bold-text">
                    <br>
                    <h3 style="font-size: 20px;">¿Cómo se aplica?</h3>
                </span>
                <br>
                <p>
                    El proceso es sencillo y pensado en tu comodidad:
                    <br>
                </p>
                <ul>
                    <li><span class="bold-text">Evaluación del vehiculo: </span> Primero, se realiza una evaluación de
                        tu auto para determinar el monto máximo del préstamo. Esta valoración se basa en el modelo, año,
                        y condiciones generales del vehículo.</li>
                    <li><span class="bold-text">Documentación: </span> Se te solicitará presentar algunos documentos
                        necesarios para la aprobación del préstamo. Esto incluye, pero no se limita a, identificación
                        oficial, comprobante de domicilio, y documentos del vehículo.
                    </li>
                    <li><span class="bold-text">Aprobación rápida: </span> En Ibancar, entendemos que el tiempo es
                        esencial. Por eso, nuestro proceso de aprobación es rápido y eficiente, permitiéndote acceder al
                        crédito en el menor tiempo posible.</li>
                    <li><span class="bold-text">Firma del acuerdo: </span>Una vez aprobado el préstamo, se firma un
                        acuerdo donde se detallan los términos y condiciones, incluyendo el plan de pago y la tasa de
                        interés
                    </li>
                    <li><span class="bold-text">Recibe tú dinero: </span>Con el acuerdo firmado, recibirás el dinero de
                        tu préstamo. Lo mejor de todo es que seguirás usando tu auto como de costumbre.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PrestamoConGarantiaDeAutoSinDejarloS1",
    components: {}
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}

.background-orange-3 {
    background-color: $orange-4;
}

.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }
}
</style>