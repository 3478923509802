<template>
    <div class="solicitalo-banner">
        <div class="container">
            <div class="banner-text">
                ¿Necesitas dinero urgente? Recíbelo en 1 día
            </div>
            <div class="banner-button">
                <div class="btn background-orange text-center text-white regular-text  btn-solicitar mb-2">
                    <router-link @click="goTo('Home', '#banner-form')"
                                 to="#"
                                 class="text-white">
                        Solicita ahora
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SolicitaloBanner',
    methods: {
        goTo(route, hash) {
            this.$router.push({ name: route, hash: hash });
        },
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

 .solicitalo-banner .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .banner-text {
        margin-right: 100px;
    }
}

@include media-breakpoint-down(lg){
    .solicitalo-banner .container {


    .banner-text {
        margin-right: 0px;
        margin-bottom: 20px;
    }
}
}

.faqs {
    color: #004680;
    text-align: center;
    font-family: 'Montserrat-Bold';
    text-decoration: underline;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.image {
    height: 450px;
}

.bold-text {
    font-family: 'Montserrat-Bold';
}

.background-orange-3 {
    background-color: $orange-4;
}

.background-orange {
    background-color: $orange;
}

.text-blue {
    color: $blue;
}

.solicitalo-banner {
    background: #004680;
    margin-top: 2rem;
    padding: 1rem;
    display: flex;
    align-items: center;

    .banner-text {
        color: #FFF;
        text-align: center;
        font-family: Montserrat-Bold;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.btn-solicitar {
    border-radius: 30px;
    padding: 10px 65px;
    ;
    min-width: 250px;
    font-family: 'Montserrat-Bold';
}

@include media-breakpoint-up(lg) {

    .text-white {
        font-size: 1.5rem;
    }

    s .image {
        height: 320px;
    }
}

@include media-breakpoint-down(md) {

    .btn-solicitar {
        font-size: 16px !important;
        height: auto;
    }

    .faqs {
        text-decoration: none;
    }

    .banner-text {

        font-size: 1.2rem !important;

    }
}

@include media-breakpoint-only(lg){
    .banner-text {
        font-size: 27px !important;
    }
}

@include media-breakpoint-between(md, lg) {

    .btn-solicitar {
        font-size: 18px !important;
        height: auto;
    }


}
</style>