<template>
    <div class="body-container">
        <BannerSection title="Préstamos por la <br class='d-none d-lg-block'/> factura de tu coche"
                       subtitle="Solución financiera rapida y segura" />
            <PerksComponent></PerksComponent>
        <PrestamoPorFacturaAutoS1 />
        <div class="ibancar-election">
            <p>¿Por qué elegir Ibancar?</p>
        </div>
        <BeneficiosIbancarCarousel />
        <!-- <SolicitaloBanner/> -->
        <PrestamoPorFacturaAutoS2 />
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PrestamoPorFacturaAutoS1 from '@/components/Landings/PrestamoPorFacturaAuto/PrestamoPorFacturaAutoS1.vue';
import PrestamoPorFacturaAutoS2 from '@/components/Landings/PrestamoPorFacturaAuto/PrestamoPorFacturaAutoS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamoPorFacturaAuto',
    components: {
        BannerSection,
        PerksComponent,
        BeneficiosIbancarCarousel,
        PrestamoPorFacturaAutoS1,
        PrestamoPorFacturaAutoS2,
        SolicitaloBannerS2
    },
    setup() {
        useMeta(
            {
                title: 'Prestamos por factura de carro',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: '¿Apurado por dinero? Tu factura de carro es tu mejor aliado para obtener un préstamo adaptado a ti. Procedimiento simple, respuesta inmediata y condiciones favorables. ¡Explora cómo mantener tu libertad financiera!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos por factura de carro'
                    }
                ]
            }
        )
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.body-container {
    margin-top: 115px;
    min-height: calc(100vh - 115px);
}

.perks-container {

    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #FFA5000A;

    div {
        gap: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ibancar-election {
    margin: 2rem 2rem;

    p {
        color: #004680;
        text-align: center;

        /* Mobile 360 px/H2 */
        font-family: 'Montserrat-Bold';
        font-size: 1.5rem;
        font-style: normal;
        line-height: normal;
    }
}

@include media-breakpoint-up(lg) {
    .perks-container {
        background-color: white;
    }
}

@include media-breakpoint-down(sm) {
    .body-container {
        margin-top: 84px;
    }
}
</style>